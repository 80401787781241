import { NextSeo } from 'next-seo';
import { useGetSetting } from '@/hooks/apis/Setting/useGetSetting';
import { useRouter } from 'next/router';

interface SEOconfigProps {
  title?: string;
  description?: string;
  logo?: string;
  favicon?: string;
}
const SEOconfig = ({ title, description, logo }: SEOconfigProps) => {
  const router = useRouter();
  const { data } = useGetSetting(router.locale || '');

  return (
    <NextSeo
      title={title}
      description={description}
      robotsProps={{
        nosnippet: true,
        notranslate: true,
        noimageindex: true,
        noarchive: true,
        maxSnippet: -1,
        maxImagePreview: 'none',
        maxVideoPreview: -1,
      }}
      openGraph={{
        title,
        description,
        type: 'website',
        locale: 'vi',
        // url: ``,
        siteName: 'VietValues',
        images: [
          {
            url:
              logo ||
              'https://res.cloudinary.com/duv3zo0oz/image/upload/v1689579526/logo_Viet_Values_Ratings_0888865bff.png',
            width: 800,
            height: 600,
            alt: 'VietValues Logo',
          },
        ],
      }}
      additionalLinkTags={[
        {
          rel: 'icon',
          href:
            data?.urlFavicon ||
            'https://res.cloudinary.com/duv3zo0oz/image/upload/v1689579526/logo_Viet_Values_Ratings_0888865bff.png',
        },
      ]}
    />
  );
};

export { SEOconfig };

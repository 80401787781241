export default {
  header: {
    title: 'Helleo',
    content: 'Write content',
  },
  homePage: {
    learnMore: 'Learn More',
  },
  ratedPage: {
    contactDescription:
      'Connect with us to get answers to your questions about the credit rating process',
  },
  contactUs: 'Contact Us',
  learnMore: 'Learn more',
  minimize: 'Read Less',
  detail: 'View details',
  footer: {
    contactUs: 'CONTACT US',
    term: 'TERMS AND CONDITIONS',
    about: 'ABOUT US',
    email: 'Via Email',
    phone: 'BY TELEPHONE',
    copyright: `© ${new Date().getFullYear()} VietValues Ratings Joint Stock Company. All rights reserved.`,
  },
  research: {
    file: 'File',
    title: 'Title',
    date: 'Date',
  },
};

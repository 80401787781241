import React from 'react';
import { LazyImage } from '../shared';

interface SectionHomeProps {
  photo: string;
  desc: string;
}

const SectionHome = ({ photo, desc }: SectionHomeProps) => {
  return (
    <div className="flex justify-between items-center py-16 lg:py-32">
      <div className="lg:pr-10 text-xl flex-col flex gap-3 whitespace-break-spaces">
        {desc}
      </div>
      <div className="hidden w-fit lg:block">
        <LazyImage
          alt={'image'}
          src={photo || ''}
          width={323}
          height={419}
          className="max-w-none"
        />
      </div>
    </div>
  );
};

export default SectionHome;

export const TYPE_QUERY_KEY = {
  GET_MENU: 'GET_MENU',
  GET_SETTING: 'GET_SETTING',
  GET_CAREER_PAGE: 'GET_CAREER_PAGE',
  GET_ABOUT_US_PAGE: 'GET_ABOUT_US_PAGE',
  GET_HOMEPAGE: 'GET_HOMEPAGE',
  GET_HOW_TO_GET_RATE: 'GET_HOW_TO_GET_RATE',
  GET_STATIC_PAGE: 'GET_STATIC_PAGE',
  GET_ALL_SLUGS_STATIC_PAGE: 'GET_ALL_SLUGS_STATIC_PAGE',
  GET_STATIC_PAGE_BY_SLUG: 'GET_STATIC_PAGE_BY_SLUG',
  GET_ALL_RESEARCH: 'GET_ALL_RESEARCH',
  GET_RESEARCH_PAGE: 'GET_RESEARCH_PAGE',
  GET_ALL_PAGE: 'GET_ALL_PAGE',
  GET_CORPORATE_DISCLOSURES: 'GET_CORPORATE_DISCLOSURES',
};

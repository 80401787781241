import { TYPE_QUERY_KEY } from '@/constants/typeQueryKey';
import { common } from '@/services/apis';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

export const useGetSetting = (locale: string) => {
  const { data, isLoading } = useQuery(
    [TYPE_QUERY_KEY.GET_SETTING, locale],
    () => common.settingApi({ locale }),
  );
  const { logo, favicon, ...rest } = data?.data?.attributes || {};

  const InfoData = useMemo(() => {
    const urlLogo = logo?.data?.[0]?.attributes?.url;
    const urlFavicon = favicon?.data?.attributes?.url;
    return {
      urlLogo,
      urlFavicon,
      ...rest,
    };
  }, [logo?.data, rest]);
  return {
    data: InfoData,
    isLoading,
  };
};

import React from 'react';
import Image from 'next/image';
// import Link from 'next/link';
import useTrans from '@/hooks/useTrans';
import { useRouter } from 'next/router';

interface CarouselItemProps {
  imgUrl: string;
  title?: string;
  desc?: string;
  link?: string;
  textTitle?: string;
  textDesc?: string;
}

const CarouselItem = (props: CarouselItemProps) => {
  const trans = useTrans();
  const { title, imgUrl, desc, link, textTitle, textDesc } = props;
  const router = useRouter();
  return (
    <div className="relative h-full w-full">
      <div className="absolute inset-0 bg-black/30 z-20" />
      <Image
        src={
          imgUrl.includes('https://')
            ? imgUrl
            : `https://cms.vietvaluesratings.com.vn${imgUrl}`
        }
        alt={title || ''}
        objectFit="cover"
        fill
      />
      <div className="absolute flex h-full w-full flex-col justify-center z-40">
        <div className="container text-white">
          <div className="pb-4 text-xl lg:text-3xl">{title}</div>
          <div className="text-sm lg:w-2/3 lg:pb-10 lg:text-lg">{desc}</div>
          {link && (
            <button
              // href={`${process.env.NEXT_PUBLIC_PROD_CMS_URL}${link}`}
              className="text-sm font-bold underline lg:text-lg"
              // target="_blank"
              type="button"
              onClick={() => {
                router.push(
                  { pathname: '/pdf-viewer', query: { fileUrl: link } },
                  '/pdf-viewer',
                );
              }}
            >
              {trans.homePage.learnMore}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CarouselItem;

export default {
  home: {
    title: 'Chào mừng',
    content: 'Bắt đầu một bài viết nào',
  },
  homePage: {
    learnMore: 'Tìm hiểu thêm',
  },
  ratedPage: {
    contactDescription:
      'Hãy kết nối với chúng tôi để được giải đáp các thắc mắc về quy trình xếp hạng tín nhiệm',
  },
  contactUs: 'Liên hệ',
  learnMore: 'Đọc thêm',
  minimize: 'Thu gọn',
  detail: 'Chi tiết',
  footer: {
    contactUs: 'LIÊN HỆ',
    term: 'ĐIỀU KHOẢN VÀ ĐIỀU KIỆN',
    about: 'GIỚI THIỆU',
    email: 'Email',
    phone: 'Điện thoại',
    copyright: `© ${new Date().getFullYear()} Công ty Cổ Phần Xếp Hạng Tín Nhiệm VietValues Ratings. Bản quyền đã được đăng ký.`,
  },
  research: {
    file: 'Tài liệu',
    title: 'Tiêu đề',
    date: 'Ngày',
  },
};

import Slider from 'react-slick';
import React, { useMemo, useRef, useState } from 'react';
import CarouselItem from './CarouselItem';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface CarouselSliderProps {
  type: 'banner' | 'image';
  showController?: boolean;
  data: {
    photo: any;
    title?: string;
    description?: string;
    pdf?: any;
  }[];
}
const CarouselSlider = ({ type, data, showController }: CarouselSliderProps) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const ref = useRef<Slider>(null);
  const renderItems = useMemo(() => {
    switch (type) {
      case 'banner':
        return data?.map((item, index) => {
          const { title, photo, description, pdf } = item;
          return (
            <div className="flex h-52 lg:h-[70vh]" key={index}>
              <CarouselItem
                imgUrl={photo?.data?.attributes?.url || photo}
                desc={description}
                link={pdf?.data?.attributes?.url}
                title={title}
              />
            </div>
          );
        });
      case 'image':
      default:
        return data?.map((item, index) => {
          const { photo } = item;
          return (
            <div className="flex h-60 lg:h-[356px]" key={index}>
              <CarouselItem imgUrl={photo} />
            </div>
          );
        });
    }
  }, [data, type]);

  const settings = useMemo(
    () => ({
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 500,
      dots: false,
      // centerPadding: '50px',
      focusOnSelect: true,
      arrows: false,
      swipeToSlide: true,
      // adaptiveHeight: true,
      // variableWidth: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      beforeChange: (current: number, next: number) => {
        setCurrentSlide(next);
      },
    }),
    [],
  );
  const controller = useMemo(() => {
    return (
      <div className="flex items-center justify-center gap-8 lg:justify-end">
        <ul className="flex items-center gap-2 rounded-xl px-3 py-2 bg-primary-variants-2">
          {data?.map((_, index) => {
            const activeClass =
              index === currentSlide ? 'border-2' : 'bg-white';
            return (
              <li
                key={index}
                onClick={() => ref.current?.slickGoTo(index)}
                className={`h-[10px] w-[10px] rounded-full cursor-pointer ${activeClass}`}
              />
            );
          })}
        </ul>
      </div>
    );
  }, [currentSlide, data]);

  return (
    <div>
      <div className="relative">
        <Slider {...settings} ref={ref}>
          {renderItems}
        </Slider>
        {showController && (
          <div className="absolute bottom-3 w-full">{controller}</div>
        )}
      </div>
    </div>
  );
};

export default CarouselSlider;

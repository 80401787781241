import axiosClient from '@/services/axiosClient';
import { ENDPOINT } from '@/services/endpoint';
import {
  IGetAllStaticPagesBySlugParams,
  IGetAllStaticPagesParams,
  IStaticPageFromData,
} from '@/interfaces/staticPage';

export const staticPageApi = {
  getAll: async () => {
    return await axiosClient.get(ENDPOINT.STATIC_PAGES, {
      params: { populate: '*' },
    });
  },

  getBySlug: async (params: IGetAllStaticPagesBySlugParams) => {
    const { slug, ...rest } = params;
    const page = (await axiosClient.get(ENDPOINT.STATIC_PAGES, {
      params: { ...rest, 'filters[slug]': slug, populate: '*' },
    })) as IStaticPageFromData;
    return page?.data?.[0];
  },

  getAllPages: async (params?: any) => {
    return await axiosClient.get(ENDPOINT.GET_ALL_PAGES, { params });
  },

  getAllSlug: async (params: IGetAllStaticPagesParams) => {
    const pages = (await axiosClient.get(ENDPOINT.STATIC_PAGES, {
      params: { populate: 'deep,2', ...params },
    })) as IStaticPageFromData;
    return pages?.data?.map(page => page?.attributes?.slug);
  },
};

import { TYPE_QUERY_KEY } from '@/constants/typeQueryKey';
import { homepageApi } from '@/services/apis/homepage';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

const useGetHomePage = (locale: string) => {
  const { data, isLoading } = useQuery(
    [TYPE_QUERY_KEY.GET_HOMEPAGE, locale],
    () => homepageApi.getData({ locale }),
  );
  const FormatData = useMemo(() => {
    const seo = data?.data?.attributes?.seo
    const description = data?.data?.attributes?.first_section?.description;
    const photo =
      data?.data?.attributes?.first_section?.photo?.data?.attributes?.url;
    const slides = data?.data?.attributes.slides;
    return { description, slides, photo, seo };
  }, [data]);
  return {
    data: FormatData,
    isLoading,
  };
};

export default useGetHomePage;

import axiosClient from '@/services/axiosClient';
import { ENDPOINT } from '@/services/endpoint';
import { IMenuFromData } from '@/interfaces/menu';
import { IParams } from '@/interfaces';

export const common = {
  getMenus: async (params: IParams = {}) =>
    (await axiosClient.get(ENDPOINT.GET_ALL_MENU, {
      params: {
        populate: 'deep,2',
        'filters[sub]': false,
        'filters[position]': 'header',
        ...params,
      },
    })) as IMenuFromData,
  settingApi: async (params: { locale?: string }) => {
    return await axiosClient.get(ENDPOINT.GET_SETTING, {
      params: { populate: '*', ...params },
    });
  },
};

export const ENDPOINT = {
  GET_ALL_MENU: '/menus',
  GET_SETTING: '/setting',
  GET_CAREER_PAGE: '/career-page',
  GET_HOMEPAGE: '/home-page',
  ABOUT_US: '/aboutus-page',
  HOW_TO_GET_PAGE: '/howto-page',
  RESEARCH: '/researchs',
  STATIC_PAGES: '/static-pages',
  RESEARCH_PAGE: '/research-page',
  GET_ALL_PAGES: '/pages',
  GET_CORPORATE_DISCLOSURES: '/corporate-disclosure',
  GET_CAREER_POSITION: '/career-positions'
};

import { IParams } from '@/interfaces';
import axiosClient from '../axiosClient';
import { ENDPOINT } from '../endpoint';

export const homepageApi = {
  getData: async (params: IParams = {}) =>
    await axiosClient.get(ENDPOINT.GET_HOMEPAGE, {
      params: {
        populate: 'deep,3',
        ...params,
      },
    }),
};

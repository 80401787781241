import axios, { AxiosError } from 'axios';
import * as process from 'process';

const axiosClient = axios.create({
  baseURL: process.env.NEXT_PUBLIC_PROD_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosClient.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error: AxiosError) {
    return Promise.reject(error.response?.data);
  },
);

export default axiosClient;
